import { MatDialogRef } from '@angular/material/dialog';
import { Component, HostListener } from '@angular/core';
import { AllowedFileTypes } from '@ceres/domain';
import { Upload } from '../upload-file/upload-file.model';

@Component({
    selector: 'ceres-upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent {
    dndFiles: File[] = [];
    uploads: Upload[] = [];
    processing = false;
    allowedFileTypes: string[] = AllowedFileTypes.CSV;

    constructor(private matDialogRef: MatDialogRef<UploadFileDialogComponent>) {}

    dndFilesChange(files: File[]) {
        this.dndFiles = files;
    }

    public cancel() {
        this.matDialogRef.close();
    }

    public confirm() {
        this.matDialogRef.close(this.dndFiles[0]);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.matDialogRef.close();
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoModule } from '@ngneat/transloco';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { RouterModule } from '@angular/router';
import { ngfModule } from 'angular-file';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilterModule } from '@ceres/filter/module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
    ButtonModule,
    LayoutModule,
    ConfirmDialogModule,
    DisableControlModule,
    LoadingModule,
    OrderByModule,
    SearchFieldModule,
    TranslateOrderModule,
    TranslationKeyExtractModule,
    HasPermissionModule
} from '@ceres/ui';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FeatureTogglesModule } from '@ceres/feature-toggles';
import { ClassificationService } from '../program/services/classification.service';
import { ErrorDialogComponent } from '../reports/dashboard/error-dialog/error-dialog.component';
import { ContractDocumentService } from '../contract/shared/document.service';
import { FeaturesModule } from '../features/features.module';
import { ProjectFilterService } from '../project/services/project-filter.service';
import { DateService } from './services/date.service';
import { BopService } from './services/bop.service';
import { ActivitiesService } from './services/activities.service';
import { TechnologiesService } from './services/technologies.service';
import { ProjectProfileService } from './services/project-profile.service';
import { ControlCenterComponent } from './components/control-center/control-center.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PaginatorService } from './services/paginator.service';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderPlaceholderComponent } from './header-placeholder/header-component-placeholder.component';

import { CurrencyPipe } from './pipes/currency.pipe';
import { LocalizedDatePipe } from './pipes/date.pipe';

import { CascadingDropdownComponent } from './components/cascading-dropdown/cascading-dropdown.component';
import { CascadePipe } from './pipes/cascade.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { CellNavigationService } from './services/cell-navigation.service';
import { FavoriteService } from './services/favorite.service';
import { NewsService } from './services/news.service';
import { PortfolioService } from './services/portfolio.service';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { AddNewsComponent } from './components/add-news/add-news.component';
import { ProgramService } from './services/program.service';
import { ProgramPickerComponent } from './components/program-picker/program-picker.component';
import { ProgramSelectionComponent } from './components/program-selection/program-selection.component';
import { ProjectSelectionComponent } from './components/project-selection/project-selection.component';
import { SkillService } from './services/skill.service';
import { ContractBusinessAreasService } from './services/contract-business-areas.service';
import { ProgramDocumentService } from './services/program-document.service';
import { CapacityService } from './services/capacity.service';
import { CapacityCalculationService } from './services/time-calculation.service';
import { FolderService } from './services/folders.service';
import { BraketsPipe } from './pipes/brakets.pipe';
import { OrderPosNumPipe } from './pipes/orderPosNum.pipe';
import { ActualPricePipe } from './pipes/actualPrice.pipe';
import { TimeSheetsService } from './services/time-sheets.service';
import { TimeSheetCalculationService } from './services/time-sheet-calculation.service';
import { BopNewDialogComponent } from './components/bop-new-dialog/bop-new-dialog.component';
import { ExtNamesPipe } from './pipes/ext-names.pipe';
import { BopAddDialogComponent } from './components/bop-add-dialog/bop-add-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SingleInputDialogComponent } from './components/single-input-dialog/single-input-dialog.component';
import { DiscardDialogComponent } from './components/discard-dialog/discard-dialog.component';
import { SearchComponent } from './header/search/search.component';
import { FilterHeaderComponent } from './components/filter-header/filter-header.component';
import { ProjectSelectedPipe } from './pipes/project-selected.pipe';
import { SelectPipe } from './pipes/select.pipe';
import { FindPipe } from './pipes/find.pipe';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { EmployeesHaveAssignedActivitiesPipe } from './pipes/employees-have-assigned-activities.pipe';
import { EmployeeIsActivePipe } from './pipes/employee-is-active.pipe';
import { TimerPipe } from './pipes/timer.pipe';
import { ProjectChartService } from './services/project-chart.service';
import { WIDGET_COMPONENTS } from './components/widget';
import { SurroundingMonthsPipe } from './pipes/surrounding-months.pipe';
import { DisplayMonthPipe } from './pipes/display-month.pipe';
import { ComingSoonComponent } from './badges/coming-soon/coming-soon.component';
import { BetaComponent } from './badges/beta/beta.component';
import { ServicePackagesService } from './services/service-packages.service';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ConvertCurrencyPipe } from './pipes/convert-currency.pipe';
import { CopyClipboardService } from './services/copy-clipboard.service';
import { DocPreviewDialogComponent } from './components/doc-preview-dialog/doc-preview-dialog.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { StepsVisualizerComponent } from './components/steps-visualizer/steps-visualizer.component';

import './register-locales';
import { ExRateBasedInputComponent } from './components/ex-rate-based-input/ex-rate-based-input.component';
import { ProjectTypeService } from './services/project-type.service';
import { HomeGuestUserService } from './services/home-guest-user.service';
import { SessionTimerComponent } from './session-timer/session-timer.component';
import { InlineNavbarComponent } from './components/inline-navbar/inline-navbar.component';
import { DecimalQuarterPipe } from './pipes/decimal-quarter.pipe';
import { ThemeService } from './services/theme.service';
import { CustomFilterComponent } from './components/custom-filter/custom-filter.component';
import { CustomFilterSaveDialogComponent } from './components/custom-filter-save-dialog/custom-filter-save-dialog.component';
import { CapacityColumnHeaderComponent } from './components/capacity-column-header/capacity-column-header.component';
import { ProjectPickerComponent } from './components/project-picker/project-picker.component';
import { BusinessPartnerConfigService } from './services/business-partner-config.service';
import { EmployeeConfigService } from './services/employee-config.service';
import { UserPickerComponent } from './user-picker/user-picker.component';
import { ScdExternDialogComponent } from './scd-extern-dialog/scd-extern-dialog.component';
import { ScdDialogComponent } from './scd-dialog/scd-dialog.component';
import { BusinessPartnerSelectionComponent } from './business-partner-selection/business-partner-selection.component';
import { BusinessPartnerNewComponent } from './business-partner-new/business-partner-new.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateRangePickerFyHeaderComponent } from './date-range-picker-fy-header/date-range-picker-fy-header.component';
import { EmployeeSettingsService } from './services/employee-settings.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslocoModule,
        FeaturesModule.forChild(),
        MatMenuModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        HeadroomModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatSliderModule,
        MatRadioModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        NgxChartsModule,
        DragDropModule,
        FilterModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatBadgeModule,
        ClickOutsideModule,
        ngfModule,
        NgCircleProgressModule.forRoot({
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300
        }),
        PdfViewerModule,
        OrderByModule,
        LoadingModule,
        TranslationKeyExtractModule,
        SearchFieldModule,
        DisableControlModule,
        ConfirmDialogModule,
        TranslateOrderModule,
        ButtonModule,
        LayoutModule,
        MatSlideToggleModule,
        TextFieldModule,
        FeatureTogglesModule,
        HasPermissionModule
    ],
    exports: [
        TranslocoModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NavbarComponent,
        BreadcrumbComponent,
        FooterComponent,
        HeaderComponent,
        HeaderPlaceholderComponent,
        SessionTimerComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatListModule,
        MatMenuModule,
        CapacityColumnHeaderComponent,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        CurrencyPipe,
        LocalizedDatePipe,
        TimerPipe,
        CascadePipe,
        DecimalQuarterPipe,
        CascadingDropdownComponent,
        ControlCenterComponent,
        ProgramPickerComponent,
        ProjectPickerComponent,
        NgxChartsModule,
        DragDropModule,
        DisableControlModule,
        SearchComponent,
        BraketsPipe,
        OrderPosNumPipe,
        ActualPricePipe,
        MatExpansionModule,
        ErrorDialogComponent,
        MatTabsModule,
        FilterModule,
        MatButtonToggleModule,
        BopNewDialogComponent,
        MatBadgeModule,
        ExtNamesPipe,
        ngfModule,
        FileUploadComponent,
        ClickOutsideModule,
        NgCircleProgressModule,
        SelectPipe,
        FindPipe,
        UploadProgressComponent,
        EmployeeIsActivePipe,
        WIDGET_COMPONENTS,
        SurroundingMonthsPipe,
        DisplayMonthPipe,
        ComingSoonComponent,
        BetaComponent,
        AutofocusDirective,
        ConvertCurrencyPipe,
        ExRateBasedInputComponent,
        EmployeeIsActivePipe,
        PdfViewerModule,
        StepsVisualizerComponent,
        OrderByModule,
        LoadingModule,
        TranslationKeyExtractModule,
        SearchFieldModule,
        ConfirmDialogModule,
        ButtonModule,
        LayoutModule,
        InlineNavbarComponent,
        CustomFilterComponent,
        CustomFilterSaveDialogComponent,
        FeatureTogglesModule,
        UserPickerComponent,
        BusinessPartnerNewComponent,
        DateRangePickerComponent
    ],
    declarations: [
        AutofocusDirective,
        FilterHeaderComponent,
        NavbarComponent,
        BreadcrumbComponent,
        FooterComponent,
        HeaderComponent,
        HeaderPlaceholderComponent,
        SessionTimerComponent,
        BreadcrumbComponent,
        CurrencyPipe,
        LocalizedDatePipe,
        TimerPipe,
        CascadePipe,
        CascadingDropdownComponent,
        DecimalQuarterPipe,
        UniquePipe,
        ControlCenterComponent,
        ImpersonationComponent,
        AddNewsComponent,
        CapacityColumnHeaderComponent,
        ProgramPickerComponent,
        ProjectPickerComponent,
        ProgramSelectionComponent,
        ProjectSelectionComponent,
        BraketsPipe,
        OrderPosNumPipe,
        ActualPricePipe,
        ErrorDialogComponent,
        BopNewDialogComponent,
        ExtNamesPipe,
        BopAddDialogComponent,
        FileUploadComponent,
        SingleInputDialogComponent,
        DiscardDialogComponent,
        SearchComponent,
        ProjectSelectedPipe,
        SelectPipe,
        FindPipe,
        UploadProgressComponent,
        EmployeesHaveAssignedActivitiesPipe,
        EmployeeIsActivePipe,
        WIDGET_COMPONENTS,
        SurroundingMonthsPipe,
        DisplayMonthPipe,
        ComingSoonComponent,
        BetaComponent,
        ConvertCurrencyPipe,
        ExRateBasedInputComponent,
        EmployeeIsActivePipe,
        DocPreviewDialogComponent,
        PdfViewerDialogComponent,
        StepsVisualizerComponent,
        InlineNavbarComponent,
        CustomFilterComponent,
        CustomFilterSaveDialogComponent,
        UserPickerComponent,
        ScdExternDialogComponent,
        ScdDialogComponent,
        BusinessPartnerSelectionComponent,
        BusinessPartnerNewComponent,
        DateRangePickerComponent,
        DateRangePickerFyHeaderComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: MatPaginatorIntl,
                    useClass: PaginatorService
                },
                {
                    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
                    useValue: { useUtc: true }
                },
                LocalizedDatePipe,
                TimerPipe,
                DecimalQuarterPipe,
                CellNavigationService,
                FavoriteService,
                ProjectProfileService,
                ProjectTypeService,
                BusinessPartnerConfigService,
                EmployeeConfigService,
                HomeGuestUserService,
                DateService,
                TechnologiesService,
                NewsService,
                PortfolioService,
                ActivitiesService,
                ProgramDocumentService,
                ProgramService,
                ClassificationService,
                BopService,
                SkillService,
                ContractBusinessAreasService,
                ProjectProfileService,
                ProjectChartService,
                CapacityService,
                CapacityCalculationService,
                FolderService,
                TimeSheetsService,
                TimeSheetCalculationService,
                ContractDocumentService,
                ServicePackagesService,
                CopyClipboardService,
                EmployeeSettingsService,
                ThemeService,
                OverlayContainer,
                ProjectFilterService
            ]
        };
    }
}

export interface ProjectType {
    id: number;
    name: ProjectTypeName;
    translationKey?: string;
}

export enum ProjectTypeName {
    Classic = 'Classic',
    Basic = 'Basic',
    GmsBasic = 'GmsBasic', //deprecated
    Proposal = 'Proposal',
    ChildProject = 'ChildProject',
    Preliminary = 'Preliminary',
    BasicCYS = 'BasicCYS'
}

/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import {
    BusinessArea,
    Employee,
    MaterialMaster,
    ProjectMetadata,
    ProjectProfile,
    ProjectNRS,
    PaginationResponse,
    ApproveProjectRequest,
    ProjectProfilePatchRequest,
    ProjectType,
    Location as CeresLocation,
    ProjectTags,
    CustomerPspElement,
    PspElement,
    PreliminaryProject
} from '@ceres/domain';
import { Filter } from '@ceres/filter';
import { map } from 'rxjs/operators';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { CommonLoading, CommonPagination } from '@ceres/frontend-helper';
import { DateService } from '../../shared/services/date.service';
import { environment } from '../../../environments/environment';

export interface Value {
    selected?: boolean;
    title?: string;
    all?: boolean;
}

// not used - deprecated
export class ProjectFilter {
    field: string;
    filters: { [key: string]: Value[] };
    customFilter: { [key: string]: Value[] };
    date: Date;
    dateEnd: Date;
}

@Injectable()
export class ProjectProfileService extends CommonPagination({ index: 0, size: 15 }, CommonLoading(Object)) {
    public referenceEmployee: Employee;
    public locations: CeresLocation[];
    public cycleOptions: { value: string; title: string }[] = [
        {
            value: 'Monthly',
            title: 'projects.project-property-options.accounting-cycle.monthly'
        },
        // { value: 'Monthly', title: 'dropdown.cycle.id2' },
        {
            value: 'Cyclic',
            title: 'projects.project-property-options.accounting-cycle.cyclic'
        }
        // { value: 'Quaterly', title: 'projects.project-property-options.accounting-cycle.cyclic-quarterly' }
    ];

    public accountingTypeOptions: { value: string; title: string }[] = [
        {
            value: 'FixedPrice',
            title: 'projects.project-property-options.accounting-type.fix-pricing'
        },
        {
            value: 'TimeAndMaterial',
            title: 'projects.project-property-options.accounting-type.time-material'
        }
    ];

    public customerTypeOptions: { value: string; title: string }[] = [
        {
            value: 'Siemens',
            title: 'projects.project-property-options.customer-type.siemens'
        },
        {
            value: 'Siemens Energy',
            title: 'projects.project-property-options.customer-type.siemens-energy'
        },
        {
            value: 'Siemens Healthineers',
            title: 'projects.project-property-options.customer-type.siemens-healthineers'
        },
        {
            value: 'Siemens Mobility',
            title: 'projects.project-property-options.customer-type.siemens-mobility'
        },
        {
            value: 'Siemens Financial Services',
            title: 'projects.project-property-options.customer-type.siemens-financials'
        },
        {
            value: 'Siemens Weitere',
            title: 'projects.project-property-options.customer-type.siemens-others'
        },
        {
            value: 'Extern',
            title: 'projects.project-property-options.customer-type.external'
        },
        {
            value: 'GMS Intern',
            title: 'projects.project-property-options.customer-type.gms-internal'
        }
    ];

    public projectStatusOptions: {
        value: string;
        title: string;
        key: string;
    }[] = [
        {
            value: 'projects.project-property-options.status.in-progress',
            title: 'projects.project-property-options.status.in-progress',
            key: 'running'
        },
        {
            value: 'projects.project-property-options.status.closed',
            title: 'projects.project-property-options.status.closed',
            key: 'closed'
        },
        {
            value: 'projects.project-property-options.status.other',
            title: 'projects.project-property-options.status.other',
            key: 'other'
        }
    ];

    public isaStatusOptions: { value: number; title: string }[] = [
        {
            value: 0,
            title: 'projects.project-property-options.isa-status.not-applicable'
        },
        { value: 1, title: 'projects.project-property-options.isa-status.open' },
        { value: 2, title: 'projects.project-property-options.isa-status.existing' }
    ];
    /*
    public projectTypeOptions: ProjectType[] = [
      { id: 1, name: ProjectTypeName.Basic },
      { id: 2, name: ProjectTypeName.Classic },
      { id: 3, name: ProjectTypeName.GmsBasic }
    ]; */
    filters: Filter[];
    fiscalYear: { date: Date; dateEnd: Date };

    public async getProjectTypes(): Promise<ProjectType[]> {
        return await this.httpClient.get<ProjectType[]>(`${environment.edgeService}/projects/types`).toPromise();
    }

    // not used - deprecated
    public projectColumnTitles = [
        'mpNumber',
        'mpTitle',
        'businessPartnerSur',
        'projectLead',
        'fsgGesamtprojekt',
        'fsgExtern',
        'projectStatus',
        'verrechnungsart',
        'auftragswert',
        'istpreis',
        'leistungsempfaenger',
        'externalCosts',
        'startDate',
        'endDate',
        'orgID',
        'costCenterInternal'
    ];

    // not used - deprecated
    public projectPerformanceColumnTitles = [
        'mpNumber',
        'mpTitle',
        'vsp',
        // 'projektGruppierung',
        'projectLead',
        'auftragswert',
        'projectStatus',
        'verrechnungsart',
        'fsgGesamtprojekt',
        'fsgWert',
        'abgerechneterWert',
        'istpreisStunden',
        'istpreisExtern',
        'remainingBudget',
        'poc-stunden-extkosten',
        'budget-stunden-extkosten',
        'spanne'
    ];

    private projectMetadata: ProjectMetadata;
    projectNrsColumnTitles = [
        'projektGruppierung',
        'projectLead',
        'costCenterInternal',
        'projectStatus',
        'kundenart',
        'mpNumber',
        'abgerechnet',
        'verrechnungsland',
        'mpTitle',
        'startDate',
        'endDate',
        'angebotsnummer',
        'orgID',
        'bestellnummer',
        'istpreis',
        'planpreis',
        'auftragswert',
        'istpreisStunden',
        'fsgGesamtprojekt',
        'fsgExtern',
        'fSGModified',
        'istkostenExtern',
        'plannedExternalCosts',
        'abteilungKunde',
        'istKosten',
        'planKosten',
        'verrechnungsart',
        'verrechnungszyklus',
        'anmerkung',
        'gesamtVerrechnet',
        'isaStatus',
        'october',
        'november',
        'december',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'are',
        'divisionCode',
        'name',
        'name2',
        'shortCompany'
    ];

    // Planpreis == Pot Geschafts.
    // VSP = Preis gegen Kosten
    //

    // private projectMetadata: Promise<ProjectMetadata> = null;

    constructor(
        private httpClient: ImpersonatedHttpClient,
        private dateService: DateService
    ) {
        super();
    }

    async getBusinessAreas(): Promise<BusinessArea[]> {
        return await this.httpClient
            .get<BusinessArea[]>(`${environment.edgeService}/projects/profiles/business/area`)
            .toPromise();
    }

    async getByFilterWithoutDate(filters: Filter[]) {
        return await this.httpClient
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}/projects/profiles`, {
                params: { filters: JSON.stringify(filters) }
            })
            .pipe(map(({ data }) => data))
            .toPromise();
    }

    async getAll() {
        this.loading = true;
        const filters = this.filters;

        let params = this.getPaginationParams();
        params = params.append('filters', JSON.stringify(filters));

        let defaults: ProjectProfile[] = [];
        if (params.get('page') === '0') {
            defaults = await this.getDefaultProjects();
        }

        return await this.httpClient
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}/projects/profiles-favorites`, {
                params
            })
            .pipe(
                map(({ data, total }) => {
                    this._total.next(total);
                    this.loading = false;
                    return { total, data: [...defaults, ...data] };
                })
            )
            .toPromise();
    }

    public setPage({ pageSize = 15, pageIndex = 0 }: { pageSize?: any; pageIndex?: any } = {}) {
        this.pageSize = pageSize || 15;
        this.pageIndex = pageIndex || 0;
    }

    public setSort(pageSort: { key: string; direction: 'asc' | 'desc' } | null) {
        this.pageSort = pageSort;
    }

    async getDefaultProjects() {
        return await this.httpClient.get<ProjectProfile[]>(`${environment.edgeService}/projects/default`).toPromise();
    }

    // not used - deprecated
    async getNRSData(filters: Filter[]): Promise<ProjectNRS[]> {
        const result = [];
        let allFetched = false;
        let page = 0;
        while (!allFetched) {
            const response = await this.httpClient
                .get<PaginationResponse<ProjectNRS>>(`${environment.edgeService}/projects/nrs`, {
                    params: {
                        filters: JSON.stringify(filters),
                        page: page.toString(),
                        size: '2000'
                    }
                })
                .pipe(map(({ data }) => data))
                .toPromise();

            result.push(...response);
            page++;
            if (response.length < 2000) {
                allFetched = true;
            }
        }
        return result;
    }

    async getAllMaterialMaster(): Promise<MaterialMaster[]> {
        return await this.httpClient
            .get<MaterialMaster[]>(`${environment.edgeService}/projects/profiles/materials`)
            .toPromise();
    }

    // not used - deprecated
    async getPersonalProjectsForProgram(employee: Employee): Promise<ProjectProfile[]> {
        const projectGrouping = employee.businessArea.title;
        const projectLeadName = employee.name;
        return await this.httpClient
            .get<PaginationResponse<ProjectProfile>>(`${environment.edgeService}/projects/employees/${employee.id}`, {
                params: { projectGrouping, projectLeadName }
            })
            .pipe(map(({ data }) => data))
            .toPromise();
    }

    async getByBFandYear(
        businessAreaId: number,
        fiscalYear?: { date: Date; dateEnd: Date }
    ): Promise<ProjectProfile[]> {
        let query = {};
        if (fiscalYear) {
            const date = fiscalYear.date.getTime().toString();
            const dateEnd = fiscalYear.dateEnd.getTime().toString();
            query = { date, dateEnd };
        }
        return await this.httpClient
            .get<PaginationResponse<ProjectProfile>>(
                `${environment.edgeService}/projects/profiles/byBusinessField/${businessAreaId}`,
                {
                    params: query
                }
            )
            .pipe(map(({ data }) => data))
            .toPromise();
    }

    // get all project profiles
    getByID(id: number): Promise<ProjectProfile> {
        return this.httpClient.get<ProjectProfile>(`${environment.edgeService}/projects/profiles/${id}`).toPromise();
    }

    // not used - deprecated
    async getByProjectNumber(prjNum: number): Promise<ProjectProfile[]> {
        return await this.httpClient
            .get<ProjectProfile[]>(`${environment.edgeService}/projects/profiles/mpn/${prjNum}`)
            .toPromise();
    }

    async create(projectProfile: ProjectProfile) {
        return await this.httpClient
            .post<ProjectProfile>(`${environment.edgeService}/projects/profiles`, projectProfile)
            .toPromise();
    }

    async createPartial(projectProfile: Partial<ProjectProfile>) {
        return await this.httpClient
            .post<ProjectProfile>(`${environment.edgeService}/projects/profiles`, projectProfile)
            .toPromise();
    }

    async update(projectProfile: ProjectProfile) {
        return await this.httpClient
            .put<ProjectProfile>(`${environment.edgeService}/projects/profiles/${projectProfile.id}`, projectProfile)
            .toPromise();
    }

    patch(projectId: number, properties: ProjectProfilePatchRequest) {
        return this.httpClient.patch<ProjectProfile>(
            `${environment.edgeService}/projects/profiles/${projectId}`,
            properties
        );
    }

    async updateProjectApproval(approveRequest: ApproveProjectRequest, id: number) {
        return await this.httpClient
            .put<any>(`${environment.edgeService}/projects/approveProject/${id}`, approveRequest)
            .toPromise();
    }

    async delete(id: number) {
        return await this.httpClient.delete(`${environment.edgeService}/projects/profiles/${id}`).toPromise();
    }

    // not used - deprecated
    public getNRSDataToExport(data: ProjectNRS[], columns: string[]) {
        return this.getDataToExport(data, columns, this.projectNrsColumnTitles);
    }

    private getDataToExport(data: ProjectNRS[], columns: string[], titles: any) {
        return data.map((nrsData) => {
            const result = {};
            columns.forEach((col) => {
                const title = titles.find((element: string) => element === col);
                result[title] = nrsData[col] || '';
            });
            return result;
        });
    }

    public async getProjectMetadata(): Promise<ProjectMetadata> {
        if (!this.projectMetadata) {
            this.projectMetadata = await this.httpClient
                .get<ProjectMetadata>(`${environment.edgeService}/projects/metadata`)
                .toPromise();
        }
        return this.projectMetadata;
    }

    public async getCustomerPspElements(): Promise<CustomerPspElement[]> {
        return await this.httpClient
            .get<CustomerPspElement[]>(`${environment.edgeService}/projects/metadata/profiles/customer-psp-elements`)
            .toPromise();
    }

    public async getInternalPspElements(): Promise<PspElement[]> {
        return await this.httpClient
            .get<PspElement[]>(`${environment.edgeService}/projects/metadata/profiles/internal-psp-elements`)
            .toPromise();
    }

    async getPossibleMPNumber(): Promise<number> {
        return await this.httpClient
            .get<{ mpNumber: number }>(`${environment.edgeService}/projects/profiles/mpn`)
            .toPromise()
            .then((result) => result.mpNumber);
    }

    getDataForExport(data: any[]) {
        const result = [];
        data.forEach((record) => {
            // if (index < data.length - 1) {
            Object.keys(record).forEach((value) => {
                if (value !== 'employee' && value !== 'singleActivity' && value !== 'index' && value !== 'isSum') {
                    result.push({
                        Employee: record[value].employee.name,
                        Activity: record[value].activity.title,
                        Day: value,
                        Hours: record[value].workingHours,
                        Comment: record[value].comment
                    });
                }
            });
            // }
        });
        const sortedResult = result.sort((a, b) => {
            if (a.Employee > b.Employee) {
                return 1;
            } else {
                return -1;
            }
        });
        return sortedResult;
    }

    async getProjectTags(): Promise<ProjectTags[]> {
        return await this.httpClient.get<ProjectTags[]>(`${environment.edgeService}/projects/tags`).toPromise();
    }

    public async createPreliminaryProject(data: PreliminaryProject): Promise<number> {
        return await this.httpClient
            .post<number>(`${environment.edgeService}/projects/profiles/preliminary`, data)
            .toPromise();
    }
}

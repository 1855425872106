import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class XlsxExportService {
    constructor(private translateService: TranslocoService) {}

    public async export(fileName: string, dataToExport: any[], asCsv?: boolean) {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
        if (asCsv) {
            const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' });
            const csvFile = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
            saveAs(csvFile, fileName);
        } else {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, fileName);
        }
        return Promise.resolve();
    }

    public async returnAsCSVFile(fileName: string, dataToExport: any[]) {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
        const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' });
        const csvFile = new Blob([csv], { type: 'text/plain' });
        const file = new File([csvFile], fileName, {
            type: 'text/plain'
        });
        return Promise.resolve(file);
    }

    public async returnFileForExport(
        fileName: string,
        dataToExport: any[],
        columns: string[],
        translationPrefix = '',
        columnsToAdd?: { key: string; index?: number }[],
        noTranslation?: boolean,
        asCsv?: boolean
    ) {
        if (columnsToAdd) {
            columns = this.addNotDisplayedColumns(columns, columnsToAdd);
        }

        const data = this.getDataToExport(dataToExport, columns, translationPrefix, noTranslation);
        return await this.export(fileName, data, asCsv);
    }

    public async genericExport(
        fileName: string,
        dataToExport: any[],
        columns: string[],
        translationPrefix = '',
        columnsToAdd?: { key: string; index?: number }[],
        noTranslation?: boolean,
        asCsv?: boolean
    ) {
        if (columnsToAdd) {
            columns = this.addNotDisplayedColumns(columns, columnsToAdd);
        }

        const data = this.getDataToExport(dataToExport, columns, translationPrefix, noTranslation);
        return await this.export(fileName, data, asCsv);
    }

    public async returnCSVFile(
        fileName: string,
        dataToExport: any[],
        columns: string[],
        translationPrefix = '',
        columnsToAdd?: { key: string; index?: number }[]
    ) {
        if (columnsToAdd) {
            columns = this.addNotDisplayedColumns(columns, columnsToAdd);
        }

        const data = this.getDataToExport(dataToExport, columns, translationPrefix);
        return this.returnAsCSVFile(fileName, data);
    }

    private getDataToExport(data: any[], columns: string[], translationPrefix: string, noTranslation?: boolean) {
        columns = columns.filter((ele) => ele !== 'save' && ele !== 'download');
        return data.map((dataElement) => {
            const newElement: any = {};

            columns.forEach((col) => {
                const prettyName = noTranslation
                    ? col
                    : this.translateService.translate(`columns.${translationPrefix}${col}`);

                // nested objects
                if (col.includes('.') && !this.checkForDateDDMMYYYY(col)) {
                    const colSplit = col.split('.');
                    const nestedObject = dataElement[colSplit[0]];
                    const element =
                        nestedObject && dataElement[colSplit[0]][colSplit[1]]
                            ? dataElement[colSplit[0]][colSplit[1]]
                            : dataElement[col]
                              ? dataElement[col]
                              : undefined;

                    // transform dates
                    if (element && this.checkForDate(element)) {
                        newElement[prettyName] = this.transformDate(element);
                    } else if (nestedObject && nestedObject instanceof Array) {
                        newElement[prettyName] = nestedObject.reduce(
                            (a, b, index) => a + `${b[colSplit[1]]}` + (index < nestedObject.length - 1 ? ', ' : ''),
                            ''
                        );
                    } else {
                        newElement[prettyName] = element ? this.translateService.translate(`${element}`) : '';
                    }
                } else {
                    const columnElement = dataElement[col];
                    if (columnElement && this.checkForDate(columnElement)) {
                        newElement[prettyName] = this.transformDate(columnElement);
                    } else {
                        newElement[prettyName] = this.getExportValue(col, dataElement, columnElement);
                    }
                }
            });
            return newElement;
        });
    }

    private getExportValue(col: string, element: any, columnElement: any) {
        switch (col) {
            case 'totalVolume':
                return (
                    (element.chargeVolumeOwn ? element.chargeVolumeOwn : 0) +
                    (element.chargeVolumeOthers ? element.chargeVolumeOthers : 0) +
                    (element.materialCosts ? element.materialCosts : 0)
                );
            case 'expectedValue':
                return (
                    ((element.chargeVolumeOwn ? element.chargeVolumeOwn : 0) +
                        (element.chargeVolumeOthers ? element.chargeVolumeOthers : 0) +
                        (element.materialCosts ? element.materialCosts : 0)) *
                    (element.probabilityClass.probability / 100)
                );
            case 'invest':
                return element.invest ? '✅' : '❌';
            case 'ignoreImport':
                return element.ignoreImport ? '✅' : '❌';
            case 'createdManually':
                return element.createdManually ? '✅' : '❌';
            case 'ME':
                return 'STD';
            case 'projectType':
                return element.projectType
                    ? this.translateService.translate(
                          `projects.project-properties.project-type.${element.projectType?.translationKey}`
                      )
                    : '-';
            default:
                if (typeof columnElement === 'number') {
                    return columnElement ? columnElement : 0;
                } else {
                    return columnElement ? columnElement : '';
                }
        }
    }

    private transformDate(date: moment.MomentInput) {
        return moment(date, 'YYYY-MM-DD', true).toDate();
    }

    private checkForDate(date: moment.MomentInput) {
        return moment(date, 'YYYY-MM-DD', true).isValid();
    }

    private checkForDateDDMMYYYY(date: moment.MomentInput) {
        return this.isValidDateCapacityPlanningFormat(date);
    }

    private isValidDateCapacityPlanningFormat(date: moment.MomentInput) {
        const formats = ['DD.MM.YYYY', 'D.MM.YYYY', 'M.YYYY', 'MM.YYYY'];
        return formats.some((format) => moment(date, format, true).isValid());
    }

    private addNotDisplayedColumns(columns: string[], columnsToAdd: { key: string; index?: number }[]) {
        const newColumns = columns.slice();
        columnsToAdd.forEach((element) => {
            if (element.index) {
                newColumns.splice(element.index, 0, element.key);
            } else {
                newColumns.push(element.key);
            }
        });
        return newColumns;
    }
}

import { Injectable } from '@angular/core';
import { FeatureTogglesConfigLoaderService } from '@ceres/feature-toggles';
import { Observable, of } from 'rxjs';
import FEATURES from '@ceres/shared/features';
import { CeresFeaturesConfig } from '@ceres/shared/features';

@Injectable()
export class StaticFeaturesAdapterService extends FeatureTogglesConfigLoaderService<CeresFeaturesConfig> {
    private config = of(FEATURES);
    loadFeatureConfig(): Observable<CeresFeaturesConfig> {
        return this.config;
    }
}

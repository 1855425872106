import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AccountingRow, Employee, Favorite, ProjectProfile, Workload, WorkloadChanges, Capacity } from '@ceres/domain';
import { isSameDay } from 'date-fns';
import * as momentTz from 'moment-timezone';
import { isNumber } from '../helpers/helpers';
import { Day } from '../models';

// case 1: new entries -> no id + work
const newEntryPredicate = (wl: Workload) => (!wl.id || wl.id === -1) && wl.workingHours > 0;

// case 2: updated entries -> id + orig val != cur val
const updatedEntryPredicate = (wl: Workload) =>
    wl.id &&
    wl.id !== -1 &&
    ((wl.workingHours && wl.workingHours !== wl.originalWorkingHours) || wl.comment !== wl.originalComment);

// case 3: deleted -> id + no work
const deletedEntryPredicate = (wl: Workload) => wl.id && wl.id !== -1 && !wl.workingHours && !wl.project;

const missingCommentPredicate = ({ sum, comment, month, isHoliday, workingHours }: MissingCommentEntry) =>
    !comment && workingHours > 0 && (sum.sum > 10 || isHoliday || month.day() === 0);

function extractAccountings({ workload }: AccountingRow) {
    return [...workload];
}

export interface MissingCommentEntry {
    sum: { workloads: Workload[]; sum: number };
    workingHours: number;
    project: ProjectProfile;
    comment: string;
    month: moment.Moment;
    isHoliday: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TimeSheetCalculationService {
    getChanges(data: AccountingRow[]) {
        return data
            .map(extractAccountings)
            .map((accounting) => {
                return {
                    new: accounting.filter(newEntryPredicate),
                    updated: accounting.filter(updatedEntryPredicate),
                    deleted: accounting.filter(deletedEntryPredicate)
                };
            })
            .reduce(
                (res, curr) => {
                    return {
                        new: res.new.concat(curr.new),
                        updated: res.updated.concat(curr.updated),
                        deleted: res.deleted.concat(curr.deleted)
                    };
                },
                {
                    new: [],
                    updated: [],
                    deleted: []
                }
            );
    }

    areChangesAvailable(data: AccountingRow[]) {
        return data
            .map(extractAccountings)
            .reduce((prev, curr) => prev.concat(curr), [])
            .some((wl) => newEntryPredicate(wl) || updatedEntryPredicate(wl) || deletedEntryPredicate(wl));
    }

    generateProjectsWithWorkloads(projects: any[], workloads: Workload[], days: Day[], employee: Employee) {
        return projects.map((project) => {
            const projectWorkloads = this.generateWorkloads(project, workloads, days, employee);
            return {
                ...project,
                workload: projectWorkloads.workloads,
                workloadsPerDay: projectWorkloads.workloadsPerDay
            } as AccountingRow;
        });
    }

    generateWorkloads(project: ProjectProfile, workloadsToMap: Workload[], days: Day[], employee: Employee) {
        const workloadsPerDay = {};
        workloadsToMap = workloadsToMap.filter((wl) => {
            if (project.mpNumber === 3) {
                return !(project as unknown as { isSum: boolean }).isSum && wl.mpNumber === project.mpNumber;
            }
            return (
                !(project as unknown as { isSum: boolean }).isSum &&
                wl.mpNumber === project.mpNumber &&
                ((wl.activity && wl.activity.id === project.singleActivity.id) ||
                    (!wl.activity && !project.singleActivity))
            );
        });
        const workloads = days.map((day) => {
            const date = momentTz.tz(day.date, 'Europe/Berlin');
            const workload = workloadsToMap.find(({ accountingMonth }) => {
                const accountingMonthDate = momentTz.tz(accountingMonth, 'Europe/Berlin');
                return (
                    accountingMonthDate.year() === date.year() &&
                    accountingMonthDate.month() === date.month() &&
                    accountingMonthDate.date() === date.date()
                );
            });
            if (workload) {
                workload.originalComment = workload.comment;
                workload.originalWorkingHours = workload.workingHours;

                workloadsPerDay[day.day] = workload;
                return workload;
            }
            workloadsPerDay[day.day] = {
                project,
                activity: project.singleActivity,
                mpNumber: project.mpNumber,
                employee,
                year: day.year,
                month: day.month,
                accountingMonth: new Date(Date.UTC(day.year, day.month - 1, day.day))
            } as Workload;
            return workloadsPerDay[day.day];
        });
        return { workloadsPerDay, workloads };
    }

    createWorkloadsForEmployees(data: Favorite[], days: Day[], workloads: Workload[]) {
        for (const item of data) {
            if (!item.workload) {
                item.workload = [];
            }
            for (const day of days) {
                const workload = workloads.filter(
                    (e) =>
                        !(item as unknown as { isSum: boolean }).isSum &&
                        e.activity &&
                        e.activity.id === (item as unknown as { singleActivity: { id: number } }).singleActivity.id &&
                        e.employee.id === item.employee.id &&
                        e.accountingMonth.getFullYear() === day.year &&
                        e.accountingMonth.getMonth() === day.month - 1 &&
                        e.accountingMonth.getDate() === day.day
                );
                for (const wl of workload) {
                    item.workload.push(wl);
                }
            }
        }
        return;
    }

    getWorkingTimePerMonth(displayedDays: Day[], employee: Employee) {
        const workingDays = displayedDays.filter(
            (day) =>
                day.value !== 0 && // not saturday
                day.value !== 6 && // not sunday
                !day.isHoliday // not public holiday
        );
        // amount of hours per week * possible working days (excluding weekend and holidays) / workdays
        return (workingDays.length * employee.sollstunden) / 5;
    }

    getMissingComments(
        allData: AccountingRow[],
        changes: WorkloadChanges,
        displayedDays: Day[]
    ): MissingCommentEntry[] {
        return changes.new
            .concat(changes.updated)
            .map((wl) => this.getCommentEntry(allData, null, displayedDays, wl))
            .filter(missingCommentPredicate);
    }

    isCommentMissing(
        allData: AccountingRow[],
        filteredData: AccountingRow[],
        displayedDays: Day[],
        workload: Workload
    ) {
        const entry = this.getCommentEntry(allData, filteredData, displayedDays, workload);
        return {
            value: missingCommentPredicate(entry),
            workloads: entry.sum.workloads
        };
    }

    getWorkingHoursPerDay(
        data: AccountingRow[] | AccountingRow,
        dateWorking: Date,
        filteredData?: AccountingRow[]
    ): { workloads: Workload[]; sum: number; filteredSum: number } {
        /**
         * @param data contains all visible projects
         */
        if (!Array.isArray(data)) {
            data = [data];
        }

        const amount = data.reduce((previous: Workload[], accountingRow): Workload[] => {
            const workload = accountingRow.workload;
            const filteredWorkload = workload.filter(({ accountingMonth }) => isSameDay(accountingMonth, dateWorking));
            return [...previous, ...filteredWorkload];
        }, []);

        let filteredSum = 0;
        if (filteredData) {
            filteredSum = filteredData
                .reduce((previous, accountingRow) => {
                    const workload = accountingRow.workload;
                    const filteredWorkload = workload.filter(({ accountingMonth }) =>
                        isSameDay(accountingMonth, dateWorking)
                    );
                    if (filteredWorkload) {
                        previous.push(...filteredWorkload);
                    }
                    return previous;
                }, [])
                .reduce((b, { workingHours }) => b + (workingHours || 0), 0);
        }

        const sum = amount.reduce((currentSum, { workingHours }) => currentSum + (workingHours || 0), 0);

        return {
            workloads: amount.filter((e) => e.workingHours),
            sum,
            filteredSum
        };
    }

    private getCommentEntry(
        allData: AccountingRow[],
        filteredData: AccountingRow[],
        displayedDays: Day[],
        { accountingMonth, project, comment, workingHours }: Workload
    ): MissingCommentEntry {
        const month = momentTz.tz(accountingMonth, 'Europe/Berlin');
        const sum = this.getWorkingHoursPerDay(allData, accountingMonth, filteredData);

        const day = displayedDays.find((e) => {
            const date = momentTz.tz(e.date, 'Europe/Berlin');
            return date.date() === month.date();
        });

        day.totalSum = sum.sum;
        day.filteredSum = sum.filteredSum;

        return {
            sum,
            workingHours,
            project,
            comment,
            month,
            isHoliday: displayedDays.some((e) => e.isHoliday && e.day === month.date())
        };
    }

    getWorkingHoursPerProject({ workload }: AccountingRow) {
        return workload ? workload.reduce((sum, curr) => sum + (curr.workingHours || 0), 0) : 0;
    }

    getWorkingHoursPerMonth(data: AccountingRow[]) {
        return data.reduce(
            (agg, element) =>
                agg +
                ((element.workload && element.workload.reduce((sum, curr) => sum + (curr.workingHours || 0), 0)) || 0),
            0
        );
    }

    getWorkload(
        item: Partial<
            ProjectProfile & {
                capacity: Capacity[];
                workload: Workload[];
                projectComment: string;
                sum: number[];
            }
        >,
        day: Day
    ) {
        return item.workload.find(({ accountingMonth }) => isSameDay(accountingMonth, day.date));
    }

    getWorkingHoursPerDayAndEmployee(
        data: Favorite[],
        favorite: Favorite,
        dayOrYear: Day | number,
        month?: number,
        day?: number
    ) {
        // ensure consistent types
        let year = dayOrYear;
        if (!isNumber(dayOrYear)) {
            year = dayOrYear.year;
            month = dayOrYear.month;
            day = dayOrYear.day;
        }

        /**
         * @param data contains all visible projects
         */
        if (!Array.isArray(data)) {
            data = [data];
        }

        return data.reduce((sum, accountingRow) => {
            if (accountingRow.employee.id === favorite.employee.id) {
                const workloads = accountingRow.workload;
                const sumOfWorkdloads = workloads.reduce((workingHoursSum, { accountingMonth, workingHours }) => {
                    if (
                        new Date(accountingMonth).getFullYear() === year &&
                        new Date(accountingMonth).getMonth() === month - 1 &&
                        new Date(accountingMonth).getDate() === day
                    ) {
                        return workingHoursSum + (workingHours || 0);
                    }
                    return workingHoursSum;
                }, 0);
                return sum + sumOfWorkdloads;
            }
        }, 0);
    }

    getWorkingHoursPerProjectNr(data: AccountingRow[], mpNumberToGet: number) {
        return data.reduce((sum, accountingRow) => {
            const workloads = accountingRow.workload;

            const sumOfWorkloads = workloads.reduce((sumOfWorkingHours, { mpNumber, workingHours }) => {
                if (mpNumber === mpNumberToGet) {
                    return sumOfWorkingHours + (workingHours || 0);
                }
                return 0;
            }, 0);
            return sum + sumOfWorkloads;
        }, 0);
    }
}
